import * as React from 'react';
import classNames from 'classnames';
import Image from '../../../Image/viewer/Image';
import { isEmptyObject } from '../../../../core/commons/utils';
import { IPaginatedGridGalleryItemProps } from '../PaginatedGridGalleryItem.types';
import ItemClickActionWrapper from '../../commons/viewer/ItemClickActionWrapper/ItemClickActionWrapper';
import { TestIds } from '../../commons/constants';
import Link from '../../../Link/viewer/Link';
import { Panel } from '../../PaginatedGridGallery/viewer/Panel';

const PaginatedGridGalleryItem: React.FC<IPaginatedGridGalleryItemProps> = props => {
  const {
    dataId,
    title,
    description,
    goToLinkText,
    link,
    image,
    skinsStyle,
    extraClassNames,
    imageOnClickAction,
    openImageZoom = () => {},
    itemClickHandler = () => {},
    translate,
    onItemMouseIn,
    onFocus,
    onBlur,
    hasPanel,
    extraStyle,
  } = props;

  const openImageZoomCallback = React.useCallback(() => openImageZoom(dataId), [
    dataId,
    openImageZoom,
  ]);

  const itemRef = React.useRef<HTMLDivElement>(null);

  const focusItemRoot = () => {
    if (itemRef.current) {
      itemRef.current.focus();
    }
  };

  return (
    <div
      className={classNames(skinsStyle.root, extraClassNames.root)}
      onMouseEnter={onItemMouseIn}
      onClick={itemClickHandler}
      tabIndex={-1}
      ref={itemRef}
      style={extraStyle}
    >
      <ItemClickActionWrapper
        imageOnClickAction={imageOnClickAction}
        openImageZoom={openImageZoomCallback}
        link={link}
        imgTitle={image.title || ''}
        imgAlt={image.alt}
        skinsStyle={skinsStyle}
        translate={translate}
        onFocus={onFocus}
        onBlur={onBlur}
        focusItemRoot={focusItemRoot}
      >
        <div className={skinsStyle.imageWrapper}>
          <div className={skinsStyle.imageBorder}>
            <Image
              className={skinsStyle.image}
              id={`img_${image.containerId}`}
              {...image}
              alt={image.alt || (image.title as string)}
            />
            <div data-testid={TestIds.panel} className={skinsStyle.panel}>
              <div className={skinsStyle.panelWrap}>
                {hasPanel && (
                  <Panel
                    title={title}
                    description={description}
                    skinsStyle={{
                      title: skinsStyle.title,
                      description: skinsStyle.description,
                    }}
                  />
                )}
                {imageOnClickAction !== 'goToLink' && !isEmptyObject(link) && (
                  <Link
                    className={skinsStyle.link}
                    dataTestId={TestIds.panelLink}
                    {...link}
                  >
                    {goToLinkText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </ItemClickActionWrapper>
    </div>
  );
};

export default PaginatedGridGalleryItem;
