import { IComponentController } from '@wix/editor-elements-types';
import { IPaginatedGridGalleryControllerActions } from '../PaginatedGridGallery.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IPaginatedGridGalleryControllerActions => ({
  setCurrentPage: (pageIndex: number) => {
    updateProps({
      currentPage: pageIndex,
    });
  },
  setAutoPlay: (autoPlay: boolean) => {
    updateProps({
      autoPlay,
    });
  },
});

export default mapActionsToProps;
